import React from 'react'
import { Navbar } from './navbar'
import { Carousel } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import mob_image2 from "../assets/slides/mob-img2.png"
import image2 from "../assets/slides/img3.png"
import slide2 from "../assets/slides/bg3.png"

import slide1 from "../assets/slides/bg2.png"
import image1 from "../assets/slides/img2.png"
import slide3 from "../assets/slides/img1_2.png"
import slide4 from "../assets/slides/bg4.png"
import slide3_1 from "../assets/slides/img1_1.png"
import slide4_1 from "../assets/slides/img4.png"
import { Footer } from './footer'


export const Support = () => {
  const isSmallScreen2 = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <div className='landingPage2'>
      <Navbar />

      <section className="HeroSection">

        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider' >
              <img className="d-block img-fluid w-100" style={{ height: "var(--sliderHeight)" }} src={slide1} alt="slide 1" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-6 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <h1 className='text-white text-uppercase'>Support</h1>
                  </div>
                  <div className='col-md-6 right_col'>
                    <img className='img-fluid side_image' src={image1} alt='image' />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div style={{ position: "relative" }} className='slider' >
              <img className="d-block img-fluid" style={{ height: "var(--sliderHeight)" }} src={slide2} alt="slide 2" />

              <div className={`${isSmallScreen2 ? "" : "container"}`}>
                <div className={`row ${isSmallScreen2 ? "" : "d-flex ai-center jc-between row_reverse"} `} style={{ height: "100vh", position: "absolute", zIndex: 100000000000000, top: "0px" }}>
                  <div className={`col-md-6 content ${isSmallScreen2 ? "order-2" : ""}`}>
                    <h1 className='text-white text-uppercase'>Support</h1>
                  </div>
                  <div className='col-md-6 right_col'>
                    <img className='img-fluid side_image_2' src={isSmallScreen2 ? mob_image2 : image2} alt='image' />
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>

              <Footer />
        
      </section>
    </div>
  )
}
