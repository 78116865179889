import { useEffect, useMemo, useState } from 'react';
import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { useAuth } from "../ContextAPI/Components/auth";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import eth from "../assets/NEW/eth.png"
import btc from "../assets/NEW/btc.png"
import ethIco from "../images/ethIco.png"
import bnbIco from "../images/bnbIco.png"
import { BsCaretDownFill, BsCaretUpFill, BsEye } from "react-icons/bs";
import { FaAngleRight, FaCoins, FaUser } from "react-icons/fa";
import { getPortfolio, addPortfolio, getLatestRates, getLatestQuotes, TX_Coin_User, DeleteThisCoin, addPortfolioWallet, getWalletByUserId, getPortfolioBuyWallet, addPosition, getPositions } from '../helpers/apis';
import SelectNew from "react-select";
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import axios from 'axios';

const handleImageError = (event) => {
    event.target.src = `${process.env.PUBLIC_URL}/icon-not-found.png`;
    event.target.onerror = null;
    event.target.alt = "Not Found";
};



export function Portfoilio({ notify, toast }) {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useState(window.localStorage.getItem("token"))
    const [currencies, setCurrencies] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [date, setDate] = useState(new Date().toISOString().slice(0, 16))
    const [rates, setRates] = useState([]);

    const [showLoader, setShowLoader] = useState(false);
    const [showBalance, setShowBalance] = useState(true);
    const [Best_Performer, setBest_Performer] = useState(null);
    const [Worst_Performer, setWorst_Performer] = useState(null);

    const [tx, setTx] = useState(null);
    const [positionData, setPositionData] = useState(null);

    const [currentPortfolio, setCurrentPortfolio] = useState("1");
    const [walletPortfolio, setWalletPortfolio] = useState([]);
    const [walletForm, setWalletForm] = useState({});
    const [showWalletForm, setShowWalletForm] = useState(false);
    const [showAddPortfolio, setShowAddPortfolio] = useState(false);
    const [showAddPosition, setShowAddPosition] = useState(false);

    const handleClosePortfolio = () => {
        setShowAddPortfolio(false);
        setShowWalletForm(false);
    }
    const handleShowPortfolio = () => setShowAddPortfolio(true);

    const handleClosePosition = () => setShowAddPosition(false);
    const handleShowPosition = () => setShowAddPosition(true);



    async function GetPortfolio() {
        try {
            const res = await getPortfolio(user?.id);
            if (res.success) {
                console.log("res.message");
                console.log(res.message);
                console.log(res.formattedData);

                if ((res.result).length > 0) {
                    console.log(res.result);
                    const data = (res.result).filter((e) => {
                        return (e.totalQuantity != 0)
                    })

                    console.log("data 6876 786 876 87 6 786 87,,,,,,,");
                    console.log(data);
                    setTx(data)

                } else {
                    setTx(null)

                }

            } else {
                setTx(null)
            }
        } catch (error) {
            console.log(error);
            setShowLoader(false)
        }

    }

    function findCurrency(name) {
        if (rates.length > 0) {
            return rates.find((e) => {
                return (e?.slug)?.toLowerCase() == name?.toLowerCase() || (e?.name)?.toLowerCase() == name?.toLowerCase() || null
            })
        }
    }
    console.log(rates);

    function decider() {
        console.log("decider -> tx", tx);
        const data = tx.map((transaction) => {
            var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
            var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;
            var totalValueUSD = transaction.totalQuantity * usdPrice;
            var pl = (usdPrice * transaction.totalQuantity) - transaction.totalValue;
            return { ...transaction, totalValueUSD, pl };
        });

        if (data.length === 0) {
            setTx(null)
            setBest_Performer(null)
            setWorst_Performer(null)
            return { min: null, max: null };
        }

        console.log("deciderd", data);


        if (data.length == 1) {
            console.log("12312331231312312312", data)
            return data.reduce((result, current) => {
                console.log("result", result);
                console.log("current", current);
                if (result.min === null || current.pl < result.min) {
                    result.min = current.pl;
                    setWorst_Performer(current)
                    setBest_Performer(current)
                    return
                }

                if (result.max === null || current.pl > result.max) {
                    result.max = current.pl;
                    setBest_Performer(current)
                    setWorst_Performer(current)
                    return
                }

                return result;
            }, { min: null, max: null });
        }

        return data.reduce((result, current) => {
            console.log("result", result);
            console.log("current", current);
            if (result.min === null || current.pl < result.min) {
                result.min = current.pl;
                setWorst_Performer(current)
            }

            if (result.max === null || current.pl > result.max) {
                result.max = current.pl;
                setBest_Performer(current)
            }

            return result;
        }, { min: null, max: null });

    }




    function calculateTotalPrice() {
        if (tx != null) {
            var AllPL = tx.map((transaction) => {
                var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
                var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;

                // console.log("transaction.totalValue", transaction);
                // console.log("usdPrice", usdPrice);

                var PL = (usdPrice * transaction.totalQuantity) - transaction.totalValue;
                return PL;
            });


            var AllTimePL = AllPL.reduce((accumulator, value) => accumulator + value, 0);
            // console.log("AllTimePL, AllPL");
            // console.log(AllTimePL, AllPL);
            return { AllTimePL, AllPL };
        }
    }

    function calculateTotalPortfolio() {
        if (tx != null) {
            // Map transactions to USD values based on rates
            var txWithUSDValues = tx.map((transaction) => {
                var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
                var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;
                var totalValueUSD = transaction.totalQuantity * usdPrice;
                return { ...transaction, totalValueUSD };
            });

            // Calculate total USD value
            var totalUSDValue = txWithUSDValues.reduce((accumulator, transaction) => accumulator + transaction.totalValueUSD, 0);
            return totalUSDValue;
        }
    }

    function calculateTotalPortfolio24hours(data) {
        return data.reduce((total, current) => total + current.h24, 0);
    }



    const GetLatestRates = async () => {
        try {
            setShowLoader(true)
            const res = await getLatestQuotes();
            console.log("res");
            console.log(res);
            
            if (res.success) {
                console.log("res.message", res.message.data)
                console.log(res.coins)
                setRates(res.message.data)
                setCurrencies(res.coins)

            } else {
                setRates([])
                setCurrencies([])
            }

            setShowLoader(false)

        } catch (error) {
            setShowLoader(false)
            console.log(error);
        }
    }



    useEffect(() => {
        if (authToken == "" || authToken == null || typeof authToken == "undefined") {
            notify("You need to login first", toast.error)
            navigate("/")
        }
        console.log("--------------------------user");
        console.log(user);
        if (user != null) {
            GetPortfolio()
            GetLatestRates()
            getUserWallet()
            GetPositions()
        }

        // Set up the interval to call GetLatestRates() every 1 minute (60 seconds)
        const intervalId = setInterval(GetLatestRates, 60000);

        // // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);


    }, [user])

    useMemo(() => {
        if (rates.length > 0 && tx != null) decider()

    }, [tx, rates])



    async function deleteThisCoin(coin) {
        const res = await DeleteThisCoin(coin, user?.id)
        if (res.success) {
            notify("Deleted successfully", toast.success)
            window.location.reload()
        }
    }

    const handleWalletForm = (e) => {
        setWalletForm({ ...walletForm, [e.target.name]: e.target.value })
    }

    const submitWallet = async (e) => {
        if (walletForm?.name && walletForm?.wallet) {
            console.log(walletForm);
            let payload = { name: walletForm?.name, wallet: walletForm?.wallet, userID: user?.id }
            const res = await addPortfolioWallet(payload)
            if (res.success) {
                notify("Wallet Connected", toast.success)
                getUserWallet()
                handleClosePortfolio()
            }
        } else {
            notify("Please fill all fields", toast.warning)
        }
    }

    const getUserWallet = async () => {
        const res = await getWalletByUserId(user?.id)
        console.log("wallet");
        console.log(res);
        setWalletPortfolio(res?.res)

    }


    async function GetPositions() {
        try {
            console.log("res.message=======================================================");
            const res = await getPositions(user?.id);
            if (res.success) {
                console.log(res);

                setPositionData(res.result)


            } else {
                setPositionData(null)
            }
        } catch (error) {
            console.log(error);
            setShowLoader(false)
        }

    }


    return <>

        <div className='landingPage2'>
            <Navbar />
        </div>

        <div className='container my-5 portfolio'>
            <div className="row">
                <div className="col-lg-3 col-sm-12 cardListShow">
                    <div className="card list">
                        <div className='head'>
                            <p>My Portfolio</p>
                        </div>

                        <div className={currentPortfolio == "1" ? "protfolio-item bg-gray" : "protfolio-item"} onClick={() => setCurrentPortfolio("1")} >
                            <div className="img text-center">
                                <FaUser style={{ fontSize: "30px" }} />
                            </div>
                            <div className="content">
                                <h5 className='mb-0'>My Portfolio</h5>
                                <p className='mb-0'>{showBalance ? (`$${(calculateTotalPortfolio())?.toFixed(2) || 0}`) : "xxxxx"}</p>
                            </div>
                        </div>
                        {walletPortfolio.map((item, key) => (
                            <div className={currentPortfolio == item?.wallet ? "protfolio-item mt-2 bg-gray" : "protfolio-item mt-2"} key={key} onClick={() => setCurrentPortfolio(item?.wallet)}>
                                <div className="img text-center">
                                    <FaUser style={{ fontSize: "30px" }} />
                                </div>
                                <div className="content">
                                    <h5 className='mb-0'>{item?.name}</h5>
                                </div>
                            </div>
                        ))}

                        <div className="add-protfolio">
                            <button className='btn' onClick={handleShowPortfolio}>+ Create Protfolio</button>
                        </div>
                        <AddPositions btn_className={""} GetPositions={GetPositions} notify={notify} toast={toast} rates={rates} currencies={currencies} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} date={date} setDate={setDate} GetPortfolio={GetPortfolio} Get_Latest_Rates={GetLatestRates} handleClosePortfolio={handleClosePortfolio} />

                    </div>
                </div>


                <div className="col-lg-9 col sm-12">
                    <div className="main">

                        {
                            showLoader == true &&
                            <div className="w-100 h-100 text-center loader">
                                <div className="spinner-border text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {
                            currentPortfolio == "1" ?
                                <>
                                    <section>
                                        <div className="head">
                                            <div className="left">
                                                <div className='info'>
                                                    <FaUser style={{ fontSize: "30px" }} /> <span>My Main Portfolio</span>
                                                </div>
                                                <div className="TotalPortfolioAmount">
                                                    {
                                                        calculateTotalPortfolio() > 0 && <h2 className='mt-2 mb-0'>{showBalance ? (calculateTotalPortfolio())?.toFixed(2) : "xxxxx"} <BsEye onClick={() => setShowBalance(!showBalance)} style={{ fontSize: "20px", cursor: "pointer" }} /> </h2>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='dont-show-on-desktop'>
                                            <div className="add-protfolio">
                                                <button className='btn' onClick={handleShowPortfolio}>+ Create Protfolio</button>
                                                <AddPositions btn_className={""} GetPositions={GetPositions} notify={notify} toast={toast} rates={rates} currencies={currencies} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} date={date} setDate={setDate} GetPortfolio={GetPortfolio} Get_Latest_Rates={GetLatestRates} handleClosePortfolio={handleClosePortfolio} />
                                            </div>
                                        </div>

                                        <div className="portfolio-area">

                                            {
                                                (tx != null) &&
                                                <div className="card enable-shadows">
                                                    <div className="topbar success"></div>
                                                    <p className='atp mb-0 pb-0'>All-time profit</p>
                                                    {
                                                        (tx != null) ?
                                                            <>
                                                                {
                                                                    (calculateTotalPrice())?.AllTimePL >= 0 ?
                                                                        <h2 className={"text-success out mb-0 pb-3 mb-1"}>&nbsp;+ ${(Math.abs((calculateTotalPrice())?.AllTimePL))?.toFixed(2)} </h2>
                                                                        :
                                                                        <h2 className={"text-danger out mb-0 pb-3 mb-1"}>&nbsp;- ${(Math.abs((calculateTotalPrice())?.AllTimePL))?.toFixed(2)} </h2>
                                                                }
                                                            </>
                                                            :
                                                            <h2 className={"text-success out mb-0 pb-3 mb-1"}>&nbsp; $0 </h2>
                                                    }
                                                </div>
                                            }
                                            {
                                                Best_Performer != null &&
                                                <div className="card">
                                                    <div className="topbar success"></div>
                                                    <p className='atp mb-0'>Best Performer</p>
                                                    {
                                                        Best_Performer != null ?
                                                            <>
                                                                <div className='cry'>
                                                                    <img src={`${process.env.PUBLIC_URL}/icon/${(findCurrency(Best_Performer?._id))?.symbol}.svg`} alt="icon" width={"30px"} onError={(e) => handleImageError(e)} />
                                                                    <h4 className='mb-0'>{Best_Performer?._id}</h4>
                                                                </div>
                                                                {
                                                                    (Best_Performer?.pl) > 0 ?
                                                                        <p className='text-success mb-0 in'>+ ${(Math.abs(Best_Performer?.pl)).toFixed(2)} </p>
                                                                        :
                                                                        <p className='text-danger mb-0 in'>- ${(Math.abs(Best_Performer?.pl)).toFixed(2)} </p>
                                                                }
                                                            </>
                                                            :
                                                            <div className='cry'>
                                                                <h4 className='mb-0'>0</h4>
                                                            </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                Worst_Performer != null &&
                                                <div className="card">
                                                    <div className="topbar success"></div>
                                                    <p className='atp mb-0'>Worst Performer</p>
                                                    {
                                                        Worst_Performer != null ?
                                                            <>
                                                                <div className='cry'>
                                                                    <img src={`${process.env.PUBLIC_URL}/icon/${(findCurrency(Worst_Performer?._id))?.symbol}.svg`} alt="icon" width={"30px"} onError={(e) => handleImageError(e)} />
                                                                    <h4 className='mb-0'>{Worst_Performer?._id}</h4>
                                                                </div>
                                                                {
                                                                    (Worst_Performer?.pl) > 0 ?
                                                                        <p className='text-success mb-0 in'>+ ${(Math.abs(Worst_Performer?.pl)).toFixed(2)} </p>
                                                                        :
                                                                        <p className='text-danger mb-0 in'>- ${(Math.abs(Worst_Performer?.pl)).toFixed(2)} </p>
                                                                }
                                                            </>
                                                            :
                                                            <div className='cry'>
                                                                <h4 className='mb-0'>0</h4>
                                                            </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </section>

                                    <section className="mt-5 bg-white p-4">
                                        <div className="row">
                                            <h4 style={{ fontWeight: 700, fontSize: "25px", color: "#022B47" }}>Assets</h4>
                                        </div>
                                        {
                                            (showLoader) ?
                                                <div className="w-100 text-center">
                                                    <div className="spinner-border text-light" style={{ fontSize: "30px", width: "4rem", height: "4rem" }} role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                                :

                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Current Price</th>
                                                                <th scope="col">Avg. Buy Price</th>
                                                                <th scope="col">Holdings</th>
                                                                <th scope="col">Profit/Loss</th>
                                                                <th scope="col">24h%</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (tx != null && tx.length > 0) ?
                                                                    tx.map((t, i) => {
                                                                        return <tr key={i} className='py-3'>
                                                                            <td scope="row">
                                                                                <img src={`${process.env.PUBLIC_URL}/icon/${((findCurrency(t?._id))?.symbol)?.toLowerCase()}.svg`} alt="icon" width={"25px"} onError={(e) => handleImageError(e)} />
                                                                                <span> {(findCurrency(t?._id))?.name} </span>
                                                                                <span> {(findCurrency(t?._id))?.symbol} </span>
                                                                            </td>

                                                                            <td>${(findCurrency(t?._id)?.quote?.USD?.price)?.toFixed(2)}</td>

                                                                            <td>${(t.averageBuyPrice)?.toFixed(2)}</td>

                                                                            <td>{t.totalQuantity}</td>

                                                                            <td className={((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) >= 0 ? "text-success" : "text-danger"}>${((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue)?.toFixed(2)}</td>

                                                                            <td
                                                                                className={((((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) / t.averageBuyPrice) * 100) >= 0 ? "text-success" : "text-danger"}>
                                                                                {((((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) / t.averageBuyPrice) * 100).toFixed(2)}%
                                                                            </td>

                                                                            <td>
                                                                                <div className="dropdown as23">

                                                                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <BsThreeDotsVertical />
                                                                                    </button>
                                                                                    <ul className="dropdown-menu">
                                                                                        <li>
                                                                                            <Button className='btn btn-light dropdown-item' onClick={() => deleteThisCoin((findCurrency(t?._id))?.name)}> Remove</Button>
                                                                                        </li>
                                                                                        <li>
                                                                                            <ViewTXs coin={(findCurrency(t?._id))?.name} />
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                    :
                                                                    <>
                                                                        <tr className='py-3'>
                                                                            <td scope="row"></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr className='py-3'>
                                                                            <td scope="row"></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                        }
                                    </section>

                                    <section className="mt-5 bg-white p-4">
                                        <div className="row">
                                            <h4 style={{ fontWeight: 700, fontSize: "25px", color: "#022B47" }}>Positions</h4>
                                        </div>
                                        {
                                            (showLoader) ?
                                                <div className="w-100 text-center">
                                                    <div className="spinner-border text-light" style={{ fontSize: "30px", width: "4rem", height: "4rem" }} role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                                :

                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Position</th>
                                                                <th scope="col">Current Price</th>
                                                                <th scope="col">ENTRY PRICE</th>
                                                                <th scope="col">Margin Price</th>
                                                                <th scope="col">liquidation Price</th>
                                                                <th scope="col">Profit/Loss</th>
                                                                <th scope="col">Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (positionData != null && positionData.length > 0) ?
                                                                    positionData.map((t, i) => {
                                                                        return <tr key={i} className='py-3'>
                                                                            <td scope="row" >
                                                                                <div className='w-mc'>
                                                                                    <img src={`${process.env.PUBLIC_URL}/icon/${((findCurrency(t?.coin))?.symbol)?.toLowerCase()}.svg`} alt="icon" width={"25px"} onError={(e) => handleImageError(e)} />
                                                                                    <span> {(findCurrency(t?.coin))?.name} </span>
                                                                                    <span> {(findCurrency(t?.coin))?.symbol} </span>
                                                                                </div>
                                                                            </td>

                                                                            <td>{t.action}</td>
                                                                            <td>${(findCurrency(t?.coin)?.quote?.USD?.price)?.toFixed(2)}</td>

                                                                            <td>${t.entryPrice}</td>
                                                                            <td>{t.marginPrice}</td>
                                                                            <td>${t.liquidationPrice}</td>

                                                                            <td className="text-success">
                                                                                0
                                                                            </td>
                                                                            <td >
                                                                                {(new Date(t.datetime)).toLocaleString()}
                                                                            </td>


                                                                        </tr>
                                                                    })
                                                                    :
                                                                    <>
                                                                        <tr className='py-3'>
                                                                            <td scope="row"></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr className='py-3'>
                                                                            <td scope="row"></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                        }
                                    </section>
                                </>
                                :
                                <WallerAssetsComp showLoader={showLoader} setShowLoader={setShowLoader} wallet={currentPortfolio} />
                        }
                    </div>
                </div >
            </div >
        </div >


        <Footer />

        <Modal show={showAddPortfolio} onHide={handleClosePortfolio} centered>
            <Modal.Header closeButton>
                <h5 className='bg-black mb-0 py-1' style={{ fontSize: "22px", fontWeight: 700 }}>Add Portfolio</h5>
            </Modal.Header>

            <Modal.Body className='px-3 portfolio-modal' close>
                {!showWalletForm &&
                    <>
                        <AddTx notify={notify} toast={toast} rates={rates} currencies={currencies} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} date={date} setDate={setDate} GetPortfolio={GetPortfolio} Get_Latest_Rates={GetLatestRates} handleClosePortfolio={handleClosePortfolio} />
                        <button className='btn btn-success me-2 mt-3 w-100 dont-show-on-mobile' onClick={() => setShowWalletForm(true)} >Connect Your Wallet</button>
                    </>
                }
                {showWalletForm &&
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="form-label">Portfolio Name</label>
                            <input type="string" className="form-control" placeholder="name..." name='name' value={walletForm?.name} onChange={(e) => handleWalletForm(e)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="form-label">Wallet Address</label>
                            <input type="string" className="form-control" placeholder="0x1234..." name='wallet' value={walletForm?.wallet} onChange={(e) => handleWalletForm(e)} />
                            <small className='op-07'>Supported networks include Ethereum</small>
                        </div>
                        <div className="col-12">
                            <button type="button" className="btn btn-success w-100 py-2" onClick={submitWallet} >Create Connection</button>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </>
}


function AddTx({ rates, currencies, selectedCurrency, setSelectedCurrency, date, setDate, GetPortfolio, notify, toast, Get_Latest_Rates, handleClosePortfolio }) {
    const { user } = useAuth()
    const [show, setShow] = useState(false);
    const [tab, setTab] = useState("buy")
    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState(0.00)
    const [transferType, setTransferType] = useState("transferIn")

    const handleClose = () => {
        setShow(false);
        handleClosePortfolio()
    }
    const handleShow = () => {
        setShow(true);
        setDate(new Date().toISOString().slice(0, 16))
    };

    const submit = async () => {

        if (selectedCurrency == null) return notify("Please select any coin", toast.error);

        let payload = {
            userID: user.id,
            coin: (selectedCurrency.name).toLowerCase(),
            quantity: quantity,
            datetime: date,
        }

        if (tab == "transfer") {
            payload.action = transferType
        } else {
            payload.action = tab
            payload.price_per_coin = price
        }

        console.log("payload", payload);

        const res = await addPortfolio(payload)
        if (res.success) {
            handleClose()
            notify(res.message, toast.success)
            GetPortfolio()
            Get_Latest_Rates()
        }

        else notify(res.message, toast.error)
    }



    return (
        <>
            {/* <Button variant="success" style={{ marginRight: "10px" }} > + Add Transaction </Button> */}
            <button className='btn btn-success me-2 add-tx-lg w-100' onClick={() => handleShow()}>Add Transaction</button>
            <button className='btn btn-success me-2 add-tx-sm w-100' onClick={() => handleShow()}>Add TX</button>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className='bg-black mb-0 py-1' style={{ fontSize: "22px", fontWeight: 700 }}>Create Portfolio</h5>
                </Modal.Header>

                <Modal.Body className='px-3 portfolio-modal' close>
                    <SelectNew
                        placeholder="Select any Coin"
                        options={rates}
                        isOptionDisabled={(option) => (option.name == '' || option.name == null)}
                        value={selectedCurrency}
                        getOptionLabel={option =>
                            `${option.name}`
                        }
                        getOptionValue={option => `${option.name}`}
                        onChange={(value) => { setSelectedCurrency(value); console.log(value); setPrice((value.quote.USD.price).toFixed(2)); }}
                        loadingMessage={"Loading..."}
                        maxMenuHeight={200}
                        required
                    />

                    <div className='atx-modal-tabs'>
                        <button className={`btn ${tab == "buy" && "text-light fw-bold active"}`} onClick={() => setTab("buy")}>Buy</button>
                        <button className={`btn ${tab == "sell" && "text-light fw-bold active"}`} onClick={() => setTab("sell")}>Sell</button>
                        {/* <button className={`btn ${tab == "transfer" && "text-light fw-bold active"}`} onClick={() => setTab("transfer")}>Transafer</button> */}
                    </div>


                    {/* {
                        tab == "transfer" &&
                        <div className="col-12">
                            <label className="form-label">Transfer</label>
                            <div className="dropdown atx-modal border">
                                <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {transferType == "transferIn" ? "Transfer In" : "Transfer Out"}
                                </button>
                                <ul className="dropdown-menu atx-modal">
                                    <li className='dropdown-item' onClick={() => setTransferType("transferIn")}>
                                        <p className='mb-0'> Transfer In</p>
                                    </li>
                                    <li className='dropdown-item' onClick={() => setTransferType("transferOut")}>
                                        <p className='mb-0'> Transfer Out</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    } */}

                    <div className="row g-3">
                        <div className={`${tab == "transfer" ? "col-12" : "col-md-6"}`}>
                            <label className="form-label">Quantity</label>
                            <input type="number" className="form-control" placeholder="0.00" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                        </div>

                        {
                            (tab == "buy" || tab == "sell") &&
                            <div className="col-md-6">
                                <label className="form-label">Price Per Coin</label>
                                <input type="number" className="form-control" placeholder="0.00" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>
                        }

                        <div className="col-12">
                            <input type="datetime-local" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>

                        {
                            (tab == "buy" || tab == "sell") &&

                            <div className='col-12'>
                                <div className="a233nb">
                                    <p>Total {tab == "buy" ? "Spent" : "Received"}</p>
                                    <h3>$ {(quantity * price).toFixed(2)}</h3>
                                </div>
                            </div>
                        }

                        <div className="col-12">
                            <button type="button" onClick={() => submit()} className="btn btn-success w-100 py-2">Add Transaction</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}

function ViewTXs(props) {
    const { user } = useAuth()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [TXs, setTXs] = useState([])

    const fetchTXs = async () => {
        const res = await TX_Coin_User({
            userID: user?.id,
            coin: props.coin
        })
        if (res.success) setTXs(res.message)
    }

    useEffect(() => {
        if (show) {
            fetchTXs()
        }

    }, [show])


    return (
        <>
            <Button className='btn btn-light dropdown-item' onClick={handleShow}> View Transactions</Button>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 style={{ margin: 0, fontWeight: 700, fontSize: "25px", color: " rgb(2, 43, 71)" }}>Transaction List</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='as78'>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Buy Price</th>
                                    <th scope="col">Holdings</th>
                                    <th scope="col">Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TXs.length > 0 && TXs.map((tx, i) => {
                                        return <tr key={i} className='py-3'>
                                            <td> {props.coin} </td>
                                            <td> {tx.action} </td>
                                            <td>${(tx.price_per_coin)?.toFixed(2)}</td>
                                            <td>{(tx.quantity)}</td>
                                            <td>{(moment(tx.datetime)).format('YYYY-MM-DD HH:mm')}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function WallerAssetsComp({ showLoader, wallet, setShowLoader }) {
    const [tx, setTx] = useState(null);
    const [portfolioData, setPortfolioData] = useState({});
    const [portfolioTokens, setPortfolioTokens] = useState([]);
    const [ethData, setEthData] = useState({});
    const [ethTokens, setEthTokens] = useState([]);
    const [bnbData, setBnbData] = useState({});
    const [bnbTokens, setBnbTokens] = useState([]);
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalEthBalance, setTotalEthBalance] = useState(0);
    const [totalBnbBalance, setTotalBnbBalance] = useState(0);
    const [activeChain, setActiveChain] = useState('eth');
    const [showBalance, setShowBalance] = useState(true);

    const porfolioData = async () => {
        try {
            if (wallet) {
                setShowLoader(true)
                // freekey allow 2 req per second, if add api key it will allow 10 req per second free
                const ethData = await getPortfolioBuyWallet(wallet)
                console.log("==============");
                console.log(ethData);
                setActiveChain('eth')
                setEthData(ethData?.ethData)
                setBnbData(ethData?.bnbData)

                setPortfolioData(ethData?.ethData)
                if (ethData?.ethData?.tokens) {
                    let tBalance = parseFloat(ethData?.ethData?.ETH?.price?.rate) * parseFloat(ethData?.ethData.ETH?.balance)
                    console.log(tBalance);
                    console.log(ethData?.ethData?.ETH);
                    setEthTokens(ethData?.ethData?.tokens)
                    setTotalEthBalance(tBalance)

                    setTotalBalance(tBalance)
                    setPortfolioTokens(ethData?.ethData?.tokens)
                } else {
                    setEthTokens([])
                    setPortfolioTokens([])
                }
                if (ethData?.bnbData?.tokens) {
                    let tBalance = parseFloat(ethData?.bnbData?.ETH?.price?.rate) * parseFloat(ethData?.bnbData.ETH?.balance)
                    console.log(tBalance);
                    console.log(ethData?.bnbData?.ETH);
                    setBnbTokens(ethData?.bnbData?.tokens)
                    setTotalBnbBalance(tBalance)
                } else {
                    setBnbTokens([])
                }
                setShowLoader(false)
            }
        } catch (e) {
            console.error(e);
            setShowLoader(false)
        }
    }

    const handleChain = (chain) => {
        if (chain == 'eth') {
            setTotalBalance(totalEthBalance)
            setPortfolioData(ethData)
            setPortfolioTokens(ethTokens)
        } else {
            setTotalBalance(totalBnbBalance)
            setPortfolioData(bnbData)
            setPortfolioTokens(bnbTokens)
        }
        setActiveChain(chain)
    }

    useEffect(() => {
        porfolioData()
    }, [wallet])


    return (
        <>
            <section>
                <div className="head">
                    <div className="left">
                        <div className='info'>
                            {/* <img src={waqarzaka} alt="" width={"30px"} onError={(e) => handleImageError(e)} /> <span>My Main Portfolio</span> */}
                            <FaUser style={{ fontSize: "30px" }} /> <span>wallet {wallet ? wallet.slice(0, 4) + "..." + wallet.slice(wallet.length - 4) : ""}</span>
                        </div>
                        <div className="TotalPortfolioAmount">
                            <h2 className='mt-2 mb-0'>{showBalance ? (totalBalance)?.toFixed(2) : "xxxxx"} <BsEye onClick={() => setShowBalance(!showBalance)} style={{ fontSize: "20px", cursor: "pointer" }} /> </h2>
                        </div>
                    </div>
                    <div className="d-flex align-items-center chainSwitchMain">
                        <button className={activeChain == "eth" ? "active" : ""} onClick={() => handleChain('eth')}>Ethereum Chain</button>
                        <button className={activeChain == "bnb" ? "active" : ""} onClick={() => handleChain('bnb')}>Binance Chain</button>
                    </div>
                </div>
            </section>

            <section className="mt-5 bg-white p-4">
                <div className="row">
                    <h4 style={{ fontWeight: 700, fontSize: "25px", color: "#022B47" }}>Assets</h4>
                </div>
                {
                    (showLoader) ?
                        <div className="w-100 text-center">
                            <div className="spinner-border text-light" style={{ fontSize: "30px", width: "4rem", height: "4rem" }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :

                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Current Price</th>
                                        <th scope="col">Holdings</th>
                                        <th scope="col">24h%</th>
                                        <th scope="col">7d%</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {portfolioData?.ETH ?
                                        <>
                                            <tr className='py-3'>
                                                <td scope="row">
                                                    <div className="d-flex align-items-center">
                                                        <img src={activeChain == 'eth' ? ethIco : bnbIco} className='me-2' alt="icon" width={"25px"} onError={(e) => handleImageError(e)} />
                                                        {activeChain == 'eth' ?
                                                            <span> Ethereum (ETH)</span>
                                                            :
                                                            <span> BNB smart chain</span>
                                                        }
                                                    </div>
                                                </td>
                                                <td>{portfolioData?.ETH?.price?.rate}</td>
                                                <td>{portfolioData?.ETH?.balance || 0}</td>
                                                <td className={portfolioData?.ETH?.price?.diff >= 0 ? "text-success" : "text-danger"}>{portfolioData?.ETH?.price?.diff}</td>
                                                <td className={portfolioData?.ETH?.price?.diff7d >= 0 ? "text-success" : "text-danger"}>{portfolioData?.ETH?.price?.diff7d}</td>
                                                <td>${(portfolioData?.ETH?.price?.rate * portfolioData?.ETH?.balance || 0).toFixed(2)}</td>
                                            </tr>
                                            {

                                                (portfolioTokens.length > 0) &&
                                                (
                                                    portfolioTokens.map((t, i) => {
                                                        return <tr key={i} className='py-3'>
                                                            <td scope="row">
                                                                <div className="d-flex">
                                                                    {/* <img src={ethIco} alt="icon" width={"25px"} onError={(e) => handleImageError(e)} /> */}
                                                                    <span className='me-2'>{t?.tokenInfo?.name}</span>
                                                                    <span>({t?.tokenInfo?.symbol})</span>
                                                                </div>
                                                            </td>
                                                            <td>{t?.tokenInfo?.price?.rate || 'not available'}</td>
                                                            <td>{t?.balance > 0 ? Math.round((t?.balance / (t?.tokenInfo?.decimals ? (10 ** parseInt(t?.tokenInfo?.decimals)) : 1))) : 0}</td>
                                                            <td className={t?.tokenInfo?.price?.diff >= 0 ? "text-success" : "text-danger"}>{t?.tokenInfo?.price?.diff || 0}</td>
                                                            <td className={t?.tokenInfo?.price?.diff7d >= 0 ? "text-success" : "text-danger"}>{t?.tokenInfo?.price?.diff7d || 0}</td>
                                                            <td>${(t?.tokenInfo?.price?.rate * t?.tokenInfo?.balance || 0).toFixed(2)}</td>
                                                        </tr>
                                                    })
                                                )

                                            }
                                        </>
                                        :
                                        <>
                                            <tr className='py-3'>
                                                <td scope="row"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr className='py-3'>
                                                <td scope="row"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </section>
        </>
    )
}

function AddPositions({ btn_className, GetPositions, rates, selectedCurrency, setSelectedCurrency, date, setDate, notify, toast, Get_Latest_Rates }) {
    const { user } = useAuth()
    const [show, setShow] = useState(false);
    const [position, setPosition] = useState("long")
    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState(0.00)
    const [formVal, setFormVal] = useState({})

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
        setDate(new Date().toISOString().slice(0, 16))
    };
    const submit = async () => {
        console.log(formVal);
        if (selectedCurrency == null) return notify("Please select any coin", toast.error);
        if (!formVal?.entryPrice) return notify("Please add entry price", toast.error);
        if (!formVal?.marginPrice) return notify("Please add margin price", toast.error);
        if (!formVal?.liquidationPrice) return notify("Please add liquidation price", toast.error);

        let payload = {
            userID: user.id,
            action: position,
            entryPrice: formVal.entryPrice,
            coin: (selectedCurrency.name).toLowerCase(),
            entryPrice: formVal.entryPrice,
            marginPrice: formVal.marginPrice,
            liquidationPrice: formVal.liquidationPrice,
            datetime: date,
        }

        // if (tab == "transfer") {
        //     payload.action = transferType
        // } else {
        //     payload.action = tab
        //     payload.price_per_coin = price
        // }

        console.log("payload", payload);

        const res = await addPosition(payload)
        console.log("res");
        console.log(res);
        if (res.success) {
            handleClose()
            setFormVal({})
            notify(res.message, toast.success)
            Get_Latest_Rates()
            GetPositions()
        }

        else notify(res.message, toast.error)
    }


    return (
        <>
            <div className="add-protfolio">
                <button className={`btn ${btn_className}`} onClick={handleShow}>+ Add Position</button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className='bg-black mb-0 py-1' style={{ fontSize: "22px", fontWeight: 700 }}>Add Future Position</h5>
                </Modal.Header>

                <Modal.Body className='px-3 portfolio-modal' close>
                    <SelectNew
                        placeholder="Select any Coin"
                        options={rates}
                        isOptionDisabled={(option) => (option.name == '' || option.name == null)}
                        value={selectedCurrency}
                        getOptionLabel={option =>
                            `${option.name}`
                        }
                        getOptionValue={option => `${option.name}`}
                        onChange={(value) => { setSelectedCurrency(value); console.log(value); setPrice((value.quote.USD.price)); }}
                        loadingMessage={"Loading..."}
                        maxMenuHeight={200}
                        required
                    />

                    <div className='atx-modal-tabs'>
                        <button className={`btn ${position == "long" && "text-light fw-bold active"}`} onClick={() => setPosition("long")}>Long</button>
                        <button className={`btn ${position == "short" && "text-light fw-bold active"}`} onClick={() => setPosition("short")}>Short</button>
                    </div>

                    <div className="row g-3">
                        <div className="col-md-6">
                            <label className="form-label">Entry Price</label>
                            <input type="number" className="form-control" placeholder="0.00" name="entryPrice" value={formVal?.entryPrice} onChange={(e) => setFormVal({ ...formVal, [e.target.name]: e.target.value })} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Margin Price</label>
                            <input type="number" className="form-control" placeholder="0.00" name="marginPrice" value={formVal?.marginPrice} onChange={(e) => setFormVal({ ...formVal, [e.target.name]: e.target.value })} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Estimated Liquidation Price</label>
                            <input type="number" className="form-control" placeholder="0.00" name="liquidationPrice" value={formVal?.liquidationPrice} onChange={(e) => setFormVal({ ...formVal, [e.target.name]: e.target.value })} />
                        </div>



                        <div className="col-12">
                            <input type="datetime-local" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>

                        {/* {
                            (tab == "buy" || tab == "sell") &&

                            <div className='col-12'>
                                <div className="a233nb">
                                    <p>Total {tab == "buy" ? "Spent" : "Received"}</p>
                                    <h3>$ {(quantity * price).toFixed(2)}</h3>
                                </div>
                            </div>
                        } */}

                        <div className="col-12">
                            <button type="button" onClick={() => submit()} className="btn btn-success w-100 py-2">Add Transaction</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}